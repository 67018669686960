
import Vue from 'vue';
import utils from '../../util';
import { Advertiser, Product, Campaign, CampaignOnly } from '../../types/advertisers';

export default Vue.extend({
  name: 'CampaignDetails',
  props: ['campaginSearch'],
  components: {
    CampaignByProductList: () => import('./components/campaignByProductList.vue'),
  },
  data: (): {
    selectedProduct: string | null;
    search: string;
    tacticData: object[];
    dropdownList: object[];
    selectedPartners: object | null;
  } => ({
    selectedProduct: '',
    search: '',
    tacticData: [],
    dropdownList: [],
    selectedPartners: null,
  }),
  created(): void {
    this.getTactics();
    this.getCampaignByProduct();
  },
  mounted(): void {
    this.clear();
  },
  computed: {
    clientList(): Array<Advertiser> {
      return this.$store.state.advertiserView?.advertisers;
    },
    productList(): Array<Product> {
      return this.$store.state.advertiserView?.products;
    },
    campaignList(): Array<Campaign> {
      return this.$store.state.advertiserView?.campaigns;
    },
    error(): string {
      return this.friendlyError(this.$store.state.advertiserView?.error);
    },
    superUser(): boolean {
      return utils.hasRight(this.$store.state.customer.user, ['SU']);
    },
  },
  methods: {
    friendlyError(err: string): string {
      if (err?.includes('getCampaignByProduct')) {
        return 'No campaigns found';
      }
      return err;
    },
    async updateSearch(params: { [key: string]: string }): Promise<void> {
      this.search = '';
      await this.clear();
      this.search = params.search;
      this.selectedProduct = params.product;
      this.getCampaignByProduct();
    },
    async clear(): Promise<void> {
      // reset all selections
      await this.$store.dispatch('advertiserView/reset');
      return;
    },
    async getTactics(): Promise<void> {
      if (!this.$store.state.customer?.tacticList) {
        await this.$store
          .dispatch('getTacticsGQL')
          .then(data => {
            this.tacticData = data;
            this.dropdownList = this.sortFilterTactics(data);
          })
          .catch((error: Error) => {
            console.log(error); // eslint-disable-line no-console
          });
      } else {
        this.tacticData = this.$store.state.customer.tacticList;
        this.dropdownList = this.sortFilterTactics(this.$store.state.customer.tacticList);
      }
    },
    sortFilterTactics(data): Product[] {
      const removedTactics = [
        'Facebook Organic',
        'Google Search Console',
        'SEO',
        'Summary',
        'Order List',
        'Order Summary',
        'home new',
        'InnovidXP',
      ];
      if (!data) return [];
      const allTactics = data
        .map(t => {
          return {
            name: t.title,
            value: t.id,
          };
        })
        // DASH-4099 - remove "Advertiser View" - Remove "Google Search Console", "SEO" and "Facebook Organic"
        .filter(t => !removedTactics.includes(t.name));
      const sortTactics = allTactics.sort((a, b) => a.name.localeCompare(b.name));
      sortTactics.unshift({ name: 'All', value: '' });
      return sortTactics;
    },
    async getCampaignByProduct(): Promise<void> {
      await this.$store
        .dispatch('advertiserView/getCampaigns', {
          campaignName: this.search,
          product: this.selectedProduct,
        })
        .catch();
    },
    selectedCampaign(campaign: CampaignOnly): void {
      const query = {
        id: campaign.advertiserId,
        tab: campaign?.campaignType?.toLowerCase() === 'siteimpact' ? 'emailsi' : campaign?.campaignType?.toLowerCase(),
        viewCampaigns: campaign.campaignId,
      };
      // open new window to avoid losing context
      const routeData = this.$router.resolve({ name: 'home', query });
      window.open(routeData.href, '_blank');
    },
  },
});
